<template>
	<div class="contract-main container strength-king">
		<div class="strength">
			<div class="main-title">
				<h1>客户见证</h1>
				<h3>10万+建企的共同选择，最贴心的资质咨询管家</h3>
			</div>
			<div class="strength-main">

				<div class="strength-contract p-2" style="background: #F5F6F8;">
					<div class="swiper-container strength-container">
						<div class="swiper-wrapper">
							<template v-for="(item, index) in comments">
								<div class="swiper-slide strength-slide" :key="index">
									<img :src="item.icon" alt="" />
									<div class="info ml-2">
										<div class="top">
											<div class="title">
												<h3>{{ item.company }}</h3>
												<p class="ml-1">{{ item.name }}</p>
											</div>
											<p class="date">{{ item.date | date }}</p>
										</div>
										<div class="desc mt-1">
											{{ item.desc }}
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
				<div class="strength-left ml-4" style="width: 38%;">
					<h2>15年建筑咨询行业领先办理经验，全国超过2000+专业顾问的贴 心服务，全程无忧办理。获得超过10万+建企的一致肯定和好评。有需 求欢迎随时致电咨询</h2>
					<div class="inp-main px-6">

						<div class="inp-list flex align-center py-1 mt-2">
							<img src="../../../assets/image/serve/why.png" />
							<input v-model="fromData.requirements" placeholder="请输入您遇到的问题" class="ml-2" />
						</div>

						<div class="inp-list flex align-center py-1 mt-2">
							<img src="../../../assets/image/serve/user.png" />
							<input v-model="fromData.username" placeholder="请输入您的姓名" class="ml-2" />
						</div>

						<div class="inp-list flex align-center py-1 mt-2">
							<img src="../../../assets/image/serve/phone.png" />
							<input v-model="fromData.phone" placeholder="请输入您的手机号码" class="ml-2" />
						</div>


					</div>
					<div class="btn-main flex justify-between align-center px-3 mt-3">
						<div class="btn-l mr-3" @click="$toConnectLink">立即咨询 </div>
						<div class="btn-r" @click="getInfo">获取解决方案</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		filters:{
			date(){
				let  ts = Math.round(new Date().getTime()/1000);
				let s =  Math.floor(Math.random()* 60 )+1
				ts = ts - ( s * 86400);
				var date = new Date(ts*1000);
				return  date.getFullYear() +'-'+ (date.getMonth()+1) + '-' + date.getDate()
			}
		},
		props: {
			fromType: {
				type: Number,
				default: 60
			}
		},
		data() {
			return {
				fromData: {
					requirements: '',
					phone: '',
					username: ''
				},
				comments: [{
						icon: require("@/assets/image/contract/1.jpg"),
						name: " 王先生",
						company: "天津市***市政工程有限公司",
						desc: "提前两月拿到证书，进度及时汇报，不错",
						date: "2020-01-21",
					},
					{
						icon: require("@/assets/image/contract/2.jpg"),
						name: "陈先生",
						company: "北京市****（北京）科技有限公司",
						desc: "收费透明，不乱收费；申报人员问题反馈也很及时",
						date: "2020-05-23",
					},
					{
						icon: require("@/assets/image/contract/3.jpg"),
						name: "刘先生",
						company: "杭州市****劳务有限公司",
						desc: "6月找同行办理机电总包资质，8月份同行告知办不下来，经人介绍找到优普道，两个月就办理成功了。",
						date: "2020-06-07",
					},
					{
						icon: require("@/assets/image/contract/4.jpg"),
						name: "魏先生",
						company: "西安市**安建建筑工程有限公司",
						desc: "感觉挺不错的，全程都不用我们操心，一站式的代办，太省心了",
						date: "2020-03-18",
					},
					{
						icon: require("@/assets/image/contract/5.jpg"),
						name: "唐先生",
						company: "南昌市**电力工程建设有限公司",
						desc: "这家收费算比较符合行业标价，收费透明，效率也高，合作挺愉快的",
						date: "2020-4-23",
					},
					{
						icon: require("@/assets/image/contract/6.jpg"),
						name: "吴先生",
						company: "哈尔滨****建筑工程有限公司",
						desc: "多家对比后选择了优普道公司，专人定制的服务、实力雄厚的展现，办理速度提前下证，五星好评！",
						date: "2020-1-06",
					},
					{
						icon: require("@/assets/image/contract/7.jpg"),
						name: "王先生",
						company: "大庆市***建筑工程有限公司",
						desc: "办理过程很顺利，资质和安全生产许可证按期拿到。",
						date: "2020-07-13",
					},
					{
						icon: require("@/assets/image/contract/8.jpg"),
						name: "吴总",
						company: "海南**能源发展有限公司",
						desc: "给优普道点赞，办理效率很高，很有实力！",
						date: "2020-10-08",
					},
					{
						icon: require("@/assets/image/contract/9.jpg"),
						name: "吴先生",
						company: "广西峻宇建筑工程有限责任公司",
						desc: "办理资质这个公司比较靠谱。下证快，服务态度好。",
						date: "2020-11-11",
					},
					{
						icon: require("@/assets/image/contract/10.jpg"),
						name: "林先生",
						company: "福建**禾通信有限公司 ",
						desc: "多种办理方案供我选择，这服务没得说，建议我怎么办理的性价比最高，赞。",
						date: "2020-09-07",
					},
					{
						icon: require("@/assets/image/contract/11.jpg"),
						name: " 谢先生",
						company: "通**通信股份有限公司",
						desc: "办理人员专业，耐心，有不懂的地方给我说的明明白白，找对地方了",
						date: "2020-08-21",
					},
					{
						icon: require("@/assets/image/contract/12.jpg"),
						name: "李生",
						company: "茂名***建设工程有限公司",
						desc: "可以办理资质也可以找人才，不用自己到处找，大力推荐",
						date: "2020-09-06",
					},
					{
						icon: require("@/assets/image/contract/13.jpg"),
						name: "陈生",
						company: "广州**绿化工程有限公司",
						desc: "缺少的人才也是这补全的，完全没有延误办理周期",
						date: "2020-07-02",
					},
					{
						icon: require("@/assets/image/contract/14.jpg"),
						name: "简女士",
						company: "珠海**机械设施工程管理有限公司",
						desc: "办事效率高，办理的人员非常专业，申报前反复核对资料，确保我一次过，提前拿证",
						date: "2020-05-20",
					},
					{
						icon: require("@/assets/image/contract/15.jpg"),
						name: "吴生",
						company: "深圳**建设工程有限公司",
						desc: "办理顺利，一次通过，需要增项资质再合作！",
						date: "2020-01-21",
					},
					{
						icon: require("@/assets/image/contract/16.jpg"),
						name: "张生",
						company: "深圳**建筑设计工程有限公司",
						desc: "给优普道点个赞，办事效率高，员工专业服务好！",
						date: "2019-04-20",
					},
					{
						icon: require("@/assets/image/contract/17.jpg"),
						name: "王工",
						company: "广州**工程有限公司",
						desc: "服务到位，跟进及时，取证很顺利",
						date: "2019-05-18",
					},
					{
						icon: require("@/assets/image/contract/18.jpg"),
						name: "邓生",
						company: "佛山**星建设工程公司",
						desc: "报价合理透明，一对一服务跟进及时，拿证很顺利，很不错的一次合作",
						date: "2019-10-20",
					},
					{
						icon: require("@/assets/image/contract/19.jpg"),
						name: " 郑女士",
						company: "珠海**工程管理有限公司 ",
						desc: "5个资质一起办理，不到4个月便拿证了。 ",
						date: "2019-06-29",
					},
				],

			}
		},
		methods: {
			// 提交信息
			getInfo() {

				let source_url = window.location.href;
				if (!/^1[3456789]\d{9}$/.test(this.fromData.phone)) {
					this.$message.error("请输入正确手机号");
					return;
				}

				this.fromData.source_url = source_url;
				this.fromData.type = this.fromType;
				// this.fromData.source_url =  10 ;
				this.$api.applyOffer(this.fromData).then((res) => {
					this.$message.success("提交成功，稍后专业顾问将为你提供专业报价");
				}).catch(err => {
					this.$message.error("提交失败", err);
				});
			},
			initStrenghSwiper() {
				var strenghSwiper = new Swiper(".strength-container", {
					slidesPerView: "auto",
					spaceBetween: 20,
					loop: true,
					direction: "vertical",
					speed: 2000,
					autoplay: 1
				});
			}
		},
		mounted() {
			this.initStrenghSwiper();
		}
	}
</script>

<style lang="scss">
	.strength-king {
		margin: 90px auto;

		.main-title {
			padding-top: 0;
		}
	}
	@media only screen and (max-width: 1024px) {
		.contract-main{
			.strength-main{
				flex-wrap: wrap;
				.strength-contract{
					width: 100% !important;
				}
				.strength-left{
					margin: 40px auto 0;
					width: 60% !important;
				}
			} 
		} 
	}
	
	@media only screen and (max-width: 750px) {
		
	}
	
	@media only screen and (max-width: 481px) {
		.strength-king {
		    margin: 30px auto;
		}
		.contract-main {
			.strength-main {
				flex-wrap: wrap;
				.strength-contract{
					padding: 5px;
				}
				.strength-left {
					width: 100% !important;
					h2{
						margin-top: 50px;
					}
				}
			}
		}
	}
</style>
