import {
	post,
	get
} from './axios'
import {
	host
} from './config'

import {setw} from './config'

let api = {

	getArticleList: function (data = {}) {
		return get(`${host}/ypd/article/list`, data);
	},
	getArticleProfession: function (data = {}) {
		return get(`${host}/ypd/article/profession`, data);
	},
	getCategoryList: function (data = {}) {
		return get(`${host}/ypd/category/list`, data);
	},
	// 提交信息
	applyOffer: function (data = {}) {
		return post(`${host}/ypd/apply/offer`, data);
	},

	getArticleDetail: function (data = {}) {
		return get(`${host}/ypd/article/detail`, data);
	},
	getCategoryChild: function (data = {}) {
		return get(`${host}/ypd/category/child`, data);
	},
	applyCategory: function (data = {}) {
		return get(`${host}/ypd/apply/category`, data);
	},
	getIntelligentList: function (data = {}) {
		return get(`${host}/ypd/intelligent/list`, data);
	},
	getBannerList: function (data = {}) {
		return get(`${host}/ypd/banner/list`, data);
	},
	getSearchInfo: function (data = {}) {
		return get(`${host}/ypd/search/info`, data);
	},
	getStylesList: function (data = {}) {
		return get(`${host}/ypd/styles/list`, data);
	},
	getCounselorList: function (data = {}) {
		return get(`${host}/ypd/counselor/list`, data);
	},
	getFilialeList: function (data = {}) {
		return get(`${host}/ypd/filiale/list`, data);
	},
	// 获得公司信息
	getFilialeInfo: function (data = {}) {
		data.setw = setw;
		return get(`${host}/ypd/filiale/info`, data);
	},
	// 获得公司信息
	getInfo: function (data = {}) {
		return get(`${host}/ypd/filiale/info`, data);
	},
	getQuestions: function (data = {}) {
		return get(`${host}/ypd/questions/list`, data);
	}
}

export default api;