<template>
	<div class="passage py-5">
		<div class="mune-cont flex justify-between align-center">
			<div class="mune-list text-center" v-for="(item,index) in imgs" :key="index">
				<div @click="navServe(index)" @mouseover="setimg(index)" @mouseout="getimg(index)" class="cursor-pointer">
					<img :src="item.img" />
					<span class="d-block">{{item.name}}</span>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgs: [{
						img: require("@/assets/image/serve/menu1.png"),
						name: '施工总承包'
					},
					{
						img: require("@/assets/image/serve/menu2.png"),
						name: '施工专业承包'
					},
					{
						img: require("@/assets/image/serve/menu3.png"),
						name: '设计类资质'
					},
					{
						img: require("@/assets/image/serve/menu4.png"),
						name: '其他类资质'
					},
				],
				imgsa: [{
						img: require("@/assets/image/serve/menua1.png"),
						name: '施工总承包'
					},
					{
						img: require("@/assets/image/serve/menua2.png"),
						name: '施工专业承包'
					},
					{
						img: require("@/assets/image/serve/menua3.png"),
						name: '设计类资质'
					},
					{
						img: require("@/assets/image/serve/menua4.png"),
						name: '其他类资质'
					},
				],
				navList: ['/contract', '/qualification', '/design', '/decoration']
			};
		},
		methods: {
			navServe(index) {
				// console.log('nav',index);
				this.$router.push(this.navList[index]);
			},
			setimg(index) {
				// this.imgs[index].img = this.imgsa[index].img ;
			},
			getimg(index) {
				// this.imgs = [
				// 	{img:require("@/assets/image/serve/menu1.png"),name:'施工总承包'},
				// 	{img:require("@/assets/image/serve/menu2.png"),name:'施工专业承包'},
				// 	{img:require("@/assets/image/serve/menu3.png"),name:'设计类资质'},
				// 	{img:require("@/assets/image/serve/menu4.png"),name:'其他类资质'},
				// ]
			}
		},
		mounted() {
			let index = this.navList.indexOf(this.$route.path);
			this.imgs[index].img = this.imgsa[index].img;
		}
	};
</script>

<style lang="scss" scoped>
	.passage {
		width: 100%;
		position: relative;
		transform: translateY(-50%);
		background: #ffffff;
		z-index: 9;
		border: 1px solid #eeeeee;
		border-image: linear-gradient(#eeeeee, #ffffff) 0 60;
		border-bottom: 0;
	}

	.mune-cont {
		width: 100%;

		.mune-list {
			width: 25%;

			img {
				width: 87px;
				height: 87px;
				background: #2070FF;
				border-radius: 16px;
			}

			span {
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #4D5154;
				margin-top: 25px;
			}
		}
	}

	@media only screen and (max-width: 481px) {
	
		.passage {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	
			.mune-cont .mune-list {
				img {
					width: 60%;
					height: auto;
					border-radius: 8px;
				}

				span {
					font-size: 11px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #989BB0;
				}
			}
		

	}
</style>
