<template>
  <div
    class="connect wow pulse"
    data-wow-delay="300ms"
    data-wow-iteration="infinite"
    data-wow-duration="2s"
  >
    <div class="connect-phone">
      <p :class="colorStatus ? 'colors' : '' "><span>免费咨询热线：</span><span>{{$store.state.companyInfo.business_line}}</span></p>
    </div>
    <a-button class="connect-btn" :class="colorStatus ? 'connect-colors' : '' "  @click="$toConnectLink">
      了解更多服务
    </a-button>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  props:{
	colorStatus:{
		type:String,
		default:''
	}	
  },
  data() {
    return {};
  },
  mounted() {
	  setTimeout(()=>{
		  console.log('colorStatus',this.colorStatus)
	  },5000)
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
};
</script>

<style lang="scss">
.connect-btn{
	border:1px solid #2070FF;
}
.connect-colors{
	border:1px solid #FFB820;
}
.connect {
  width: 52%;
  height: 54px;
  margin: 100px auto 0;
  @include flex-center();

  &-phone {
    width: 70%;
    height: 100%;
    text-align: center;

    p {
      @include flex-center();
      height: 100%;
      font-size: 20px;
      font-weight: 400;
      color: $primary-color;
      border: 1px solid #2070ff;
      border-top-left-radius: 26px;
      border-bottom-left-radius: 26px;

      span {
        &:nth-child(2) {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
	.colors{
		color: #FFB820;
		border: 1px solid #FFB820;
	}
  }

  &-btn {
    border-radius: 0;
    border-top-right-radius: 26px;
    border-bottom-right-radius: 26px;
    width: 30%;
    height: 100%;
    font-size: 20px;
	background: #2070FF;
  }
  &-colors{
  	background: #FFB820;
	color: #FFFFFF;
  }
}
@media only screen and (max-width: 1024px) {
  .connect {
    width: 70%;
  }
}
@media only screen and (max-width: 750px) {
  .connect {
    width: 90%;
    height: 54px;
    background: #ffffff;
    margin: 40px auto 0;
    @include flex-center();

    &-phone {
      width: 70%;
      height: 100%;
      text-align: center;

      p {
        @include flex-center();
        height: 100%;
        font-size: 20px;
        font-weight: 400;
        color: $primary-color;
        border: 1px solid #2070ff;
        border-top-left-radius: 26px;
        border-bottom-left-radius: 26px;

        span {
          &:nth-child(2) {
            font-size: 25px;
            font-weight: bold;
          }
        }
      }
    }

    &-btn {
      font-size: 18px;
    }
  }
}
@media only screen and (max-width: 481px) {
  .connect {
    width: 90%;
    height: 40px;
    background: #ffffff;
    margin: 0px auto 30px;
    @include flex-center();

    &-phone {
      width: 60%;
      p {
        font-size: 16px;

        span {
          font-size: 16px;
          &:nth-child(1) {
            display: none;
          }
		  &:nth-child(2) {
		    font-size: 16px;
		  }
        }
      }
    }

    &-btn {
      width: 40%;
      font-size: 13px;
    }
  }
}
</style>
