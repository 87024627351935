<template>
  <div class="pop">
    <div class="pop-btn" @click="showPop">
      <div>
        <div></div>
      </div>
      <img class="kefu" src="@/assets/image/pop/4.png" alt="" />
      <img class="hi" src="@/assets/image/pop/2.png" alt="" />
    </div>
    <div class="pop-content animated" v-show="isPop" :class="{ fadeIn: isPop }">
      <img class="header-img" src="@/assets/image/pop/5.png" alt="" />
      <div class="content-main">
        <div class="info">
          <div class="phone">
            <p>下证效率高达<span>90%</span></p>
            <a :href="'tel:' + $store.state.companyInfo.business_line"
              ><img src="@/assets/image/pop/1.png" alt="" />{{
                $store.state.companyInfo.business_line
              }}</a
            >
          </div>
          <div class="qrcode">
            <img :src="$store.state.companyInfo.adviser_temp" alt="" />
            <p>免费资质顾问 一对一指导</p>
          </div>
        </div>
        <div class="btn">
          <a-button class="later" @click="closePop()">稍后再说</a-button>
          <a-button class="now" type="primary" @click="$toConnectLink()"
            >现在咨询</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPop: true,
      timer: null,
    };
  },
  methods: {
    showPop() {
      this.isPop = !this.isPop;
    },
    closePop() {
      this.isPop = false;
      this.startInterval();
    },
    startInterval() {
      this.timer = setInterval(() => {
        this.isPop = true;
        if (this.isPop) {
          clearInterval(this.timer);
        }
      }, 90000);
    },
  },
  created() {
    this.startInterval();
  },
};
</script>

<style lang="scss">
@keyframes scale {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}

@keyframes lbody {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  20% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  80% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

.pop {
   // display: none;
  .pop-btn {
    position: fixed;
    left: 10px;
    bottom: 20%;
    width: 108px;
    height: 108px;
    background: #1f70ff;
    border-radius: 50%;
    @include flex-center(column);
    z-index: 99;
    cursor: pointer;

    &:hover {
      .hi {
        width: auto;
        height: auto;
        opacity: 1;
      }
    }

    div {
      @include flex-center(column);
      width: 98px;
      height: 98px;
      background: rgba($color: #ffffff, $alpha: 0.12);
      border-radius: 50%;
      animation: 2s scale linear infinite normal;
      // transform: scale(1.1);

      div {
        animation: 4s scale linear infinite normal;
        width: 88px;
        height: 88px;
        background: rgba($color: #1f90ff, $alpha: 1);
        border-radius: 50%;
        @include flex-center(column);
      }
    }

    .kefu {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .hi {
      position: absolute;
      top: 0;
      left: 0;
      animation: 8s lbody linear infinite normal;
    }
  }

  .pop-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 490px;

    .header-img {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    .content-main {
      border: 2px solid #1f70ff;
      border-top: none;
      background: #ffffff;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      overflow: hidden;
      transform: translateY(-10px);
    }

    .info {
      padding: 20px 20px 0 10px;
      @include flex-between();

      .phone {
        width: 66%;

        p {
          text-align: center;
          font-size: 17px;
          font-weight: 500;
          font-style: italic;
          color: #383d41;

          span {
            margin-left: 1%;
            font-size: 23px;
            font-weight: bolder;
            color: #ff9600;
          }
        }

        a {
          img {
            max-width: 16px;
            margin-right: 10px;
          }

          width: 70%;
          margin: 18px auto 0;
          display: block;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #0066ff;
          border-radius: 30px;
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
        }
      }

      .qrcode {
        width: 32%;
        padding: 4px;
        @include flex-center(column);
        background: #ffffff;
        border: 1px solid #d9dbe8;

        img {
          max-width: 80%;
        }

        p {
          font-size: 10px;
          color: #383d41;
        }
      }
    }

    .btn {
      background: #ffffff;
      padding: 30px 0 20px;
      @include flex-center();

      .ant-btn {
        width: 26%;
        height: 33px;
        border: 1px solid #1f70ff;
        border-radius: 8px;
        font-size: 15px;
      }

      .later {
        margin-right: 10%;
        color: #1f70ff;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
}
@media only screen and (max-width: 481px) {
  .pop {
    .pop-btn {
      position: fixed;
      left: 10px;
      bottom: 20%;
      width: 60px;
      height: 60px;
      background: #1f70ff;
      border-radius: 50%;
      @include flex-center(column);
      z-index: 99;
      cursor: pointer;

      &:hover {
        .hi {
          width: auto;
          height: auto;
          opacity: 1;
        }
      }

      div {
        @include flex-center(column);
        width: 54px;
        height: 54px;
        background: rgba($color: #ffffff, $alpha: 0.12);
        border-radius: 50%;
        animation: 2s scale linear infinite normal;
        // transform: scale(1.1);

        div {
          animation: 4s scale linear infinite normal;
          width: 50px;
          height: 50px;
          background: rgba($color: #1f90ff, $alpha: 1);
          border-radius: 50%;
          @include flex-center(column);
        }
      }

      .kefu {
        width: 46px;
        height: 46px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .hi {
        position: absolute;
        top: 0;
        left: 0;
        animation: 5s lbody linear infinite normal;
        width: 30px;
      }
    }

    .pop-content {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      width: 90%;

      .header-img {
        width: 100%;
        position: relative;
        z-index: 1;
      }

      .info {
        .phone {
          a {
            width: 100%;
          }
        }

        .qrcode {
          p {
            text-align: center;
          }
        }
      }

      .btn {
        background: #ffffff;
        padding: 30px 0 20px;
        @include flex-center();

        .ant-btn {
          width: 40%;
        }
      }
    }
  }
}
</style>
