<template>
	<div class="footer pt-4 pb-6">
		<div class="footer-content">
			<div class="company">
				<div class="company-info pr-4">
					<div class="address">
						<h3 class="title mb-2">企业分布</h3>
						<div class="address-content">
							<a v-for="(item, index) in address" :key="index" :href="item.domain" target="_blank">
								{{ item.area_name }}
							</a>
						</div>
					</div>

					<div class="phone mt-3">
						<a :href="'tel:' + $store.state.companyInfo.business_line"><img src="@/assets/image/50.png" alt="" />全国咨询热线:{{
                $store.state.companyInfo.business_line
              }}</a>
						<span>（ 周一至周日 8:00-22:00 ）</span>
					</div>
				</div>
				<div class="qrCode">
					<div class="customer">
						<h3 class="title mb-2">[专业顾问]</h3>
						<div class="img-box">
							<img :src="$store.state.companyInfo.adviser_temp" alt="专业顾问" />
						</div>
					</div>
					<div class="customer">
						<h3 class="title mb-2">微信公众号</h3>
						<div class="img-box">
							<img src="@/assets/image/guwen.jpg" alt="微信公众号" />
						</div>
					</div>
				</div>
			</div>
			<div class="copyright mt-4">
				<div>
					<p class="copyright-top">
						<span> {{ $store.state.companyInfo.copyright }} </span>
						<span> 版权均归：{{ $store.state.companyInfo.name }} </span>
						<span> {{ $store.state.companyInfo.address }} </span>
					</p>
					<div class="copyright-bottom mt-1">
						<a target="_blank" href="https://beian.miit.gov.cn/">{{
              $store.state.companyInfo.icp_sn
            }}</a>
						<a target="_blank" v-if="code !== ''" :href="
                'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' +
                code
              "
						 style="display: inline-block"><img src="" /> {{ $store.state.companyInfo.network_code }}号</a>
						<router-link to="/">隐私协议</router-link>
						<router-link to="/">版权声明</router-link>
						<router-link to="/">反盗链声明</router-link>
						<!-- <div style="width: 300px; margin: 0 auto; padding: 20px 0"></div> -->
					</div>
				</div>
				<div class="license">
					<a target="_blank" :href="$store.state.companyInfo.business_license">
						<img src="@/assets/image/01.png" alt="" />
						<p>经营执照<br />信息查看</p>
					</a>
					<a target="_blank" href="https://www.12377.cn/">
						<img src="@/assets/image/02.png" alt="" />
						<p>网络110<br />报警服务</p>
					</a>
					<a target="_blank" href="https://beian.miit.gov.cn/">
						<img src="@/assets/image/03.png" alt="" />
						<p>公安备案<br />信息查询</p>
					</a>
				</div>
			</div>
		</div>
		<div class="m-footer-content">
			<div class="mContent">
				<div class="info">
					<div class="phone">
						<a :href="'tel:' + $store.state.companyInfo.business_line"><img src="@/assets/image/50.png" alt="" />全国咨询热线:
							{{ $store.state.companyInfo.business_line }}</a>
						<span>( 周一至周日 8:00-22:00 )</span>
					</div>
					<p class="nomal copyright">
						{{ $store.state.companyInfo.copyright }}
					</p>

					<div class="nomal copyright-bottom">
						<router-link to="/">隐私协议</router-link>
						<router-link to="/">版权声明</router-link>
						<router-link to="/">反盗链声明</router-link>
					</div>
					<a class="nomal" target="_blank" href="https://beian.miit.gov.cn/">{{
            $store.state.companyInfo.icp_sn
          }}</a>
					<a v-if="code !== ''" class="nomal" target="_blank" :href="
              'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' +
              code
            "
					 style="display: inline-block"><img src="" /> {{ $store.state.companyInfo.network_code }}号</a>
					<p class="nomal company">
						版权均归：{{ $store.state.companyInfo.name }}
					</p>
				</div>
				<div class="qrCode">
					<div class="customer">
						<div class="img-box">
							<img :src="$store.state.companyInfo.adviser_temp" alt="专业顾问" />
						</div>
						<h3 class="title mt-1">[专业顾问]</h3>
					</div>
					<div class="customer">
						<div class="img-box">
							<img src="@/assets/image/guwen.jpg" alt="微信公众号" />
						</div>
						<h3 class="title mt-1">微信公众号</h3>
					</div>
				</div>
			</div>

			<div class="license">
				<a target="_blank" :href="$store.state.companyInfo.business_license">
					<img src="@/assets/image/01.png" alt="" />
					<p>经营执照<br />信息查看</p>
				</a>
				<a target="_blank" href="https://www.12377.cn/">
					<img src="@/assets/image/02.png" alt="" />
					<p>网络110<br />报警服务</p>
				</a>
				<a target="_blank" href="https://beian.miit.gov.cn/">
					<img src="@/assets/image/03.png" alt="" />
					<p>公安备案<br />信息查询</p>
				</a>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";

	export default {
		data() {
			return {
				address: [],
				code: "",
			};
		},
		computed: {
			...mapState({
				networkCode: (state) => state.companyInfo.network_code,
			}),
		},
		methods: {
			getFilialeList() {
				this.$api.getFilialeList().then((res) => {
					this.address = res.data.list;
					// this.address=this.address.sort((a, b) => {
					// 	console.log(a.area_name.length,b.area_name.length)
					// 	return a.area_name.length > b.area_name.length;
					// })
				});
			},
		},
		watch: {
			networkCode: {
				handler(newVal, oldVal) {
					if (newVal) {
						this.code = newVal.replace(/[^0-9]/gi, "");
					}
				},
				immediate: true,
			},
		},
		created() {
			// let code = this.$store.state.companyInfo.network_code;
			// console.log(this.networkCode, "ssssss");

			// if (code !== "") {
			//   this.networkCode = code.replace(/[^0-9]/gi, "");
			//   console.log(this.networkCode);
			// }

			this.getFilialeList();
		},
	};
</script>
<style lang="scss" scoped>
	@import "@/assets/css/public.scss";
</style>
