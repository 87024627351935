<template>
	<div class="video-main bg-cent" :class=" ifcolor ? 'video-mainnew' : '' ">
		<div class="contract-main container">
			<div class="main-title">
				资质办理现状
			</div>
			<div class="video-box flex flex-wrap">
				<div class="video-l flex-1">
					<video type="video/mp4" autoplay="autoplay" preload="none" controls="controls" playsinline="true"
					 webkit-playsinline="true" x5-playsinline="true" x5-video-player-type="h5" :src="video_url"></video>
				</div>
				<div class="video-r">
					<div class="video-r-list" v-for="(item,index) in dataList" :key="index" @mouseenter="pickerVideo(index)" :class="videoList == index ? 'active' : '' ">
						<h4>{{item.name}}</h4>
						<span class="test-hide-to mt-1">{{item.answer}}</span>
					</div>
				</div>
			</div>
			<connect :colorStatus='ifcolor'></connect>
		</div>
	</div>
</template>

<script>
	import connect from '../connect.vue'
	export default {
		props: {
			ifcolor: {
				type: String,
				default: ''
			},
			fromData: {
				type: Object,
				default: {
					scope: 10,
					launchPage: 10
				}
			}
		},
		components: {
			connect
		},
		data() {
			return {
				comments: [{
						icon: require("@/assets/image/contract/1.jpg"),
						name: " 王先生",
						company: "天津市***市政工程有限公司",
						desc: "提前两月拿到证书，进度及时汇报，不错",
						date: "2020-01-21",
					},
					{
						icon: require("@/assets/image/contract/2.jpg"),
						name: "陈先生",
						company: "北京市****（北京）科技有限公司",
						desc: "收费透明，不乱收费；申报人员问题反馈也很及时",
						date: "2020-05-23",
					},
					{
						icon: require("@/assets/image/contract/3.jpg"),
						name: "刘先生",
						company: "杭州市****劳务有限公司",
						desc: "6月找同行办理机电总包资质，8月份同行告知办不下来，经人介绍找到优普道，两个月就办理成功了。",
						date: "2020-06-07",
					},
					{
						icon: require("@/assets/image/contract/4.jpg"),
						name: "魏先生",
						company: "西安市**安建建筑工程有限公司",
						desc: "感觉挺不错的，全程都不用我们操心，一站式的代办，太省心了",
						date: "2020-03-18",
					},
					{
						icon: require("@/assets/image/contract/5.jpg"),
						name: "唐先生",
						company: "南昌市**电力工程建设有限公司",
						desc: "这家收费算比较符合行业标价，收费透明，效率也高，合作挺愉快的",
						date: "2020-4-23",
					},
					{
						icon: require("@/assets/image/contract/6.jpg"),
						name: "吴先生",
						company: "哈尔滨****建筑工程有限公司",
						desc: "多家对比后选择了优普道公司，专人定制的服务、实力雄厚的展现，办理速度提前下证，五星好评！",
						date: "2020-1-06",
					},
					{
						icon: require("@/assets/image/contract/7.jpg"),
						name: "王先生",
						company: "大庆市***建筑工程有限公司",
						desc: "办理过程很顺利，资质和安全生产许可证按期拿到。",
						date: "2020-07-13",
					},
					{
						icon: require("@/assets/image/contract/8.jpg"),
						name: "吴总",
						company: "海南**能源发展有限公司",
						desc: "给优普道点赞，办理效率很高，很有实力！",
						date: "2020-10-08",
					},
					{
						icon: require("@/assets/image/contract/9.jpg"),
						name: "吴先生",
						company: "广西峻宇建筑工程有限责任公司",
						desc: "办理资质这个公司比较靠谱。下证快，服务态度好。",
						date: "2020-11-11",
					},
					{
						icon: require("@/assets/image/contract/10.jpg"),
						name: "林先生",
						company: "福建**禾通信有限公司 ",
						desc: "多种办理方案供我选择，这服务没得说，建议我怎么办理的性价比最高，赞。",
						date: "2020-09-07",
					},
					{
						icon: require("@/assets/image/contract/11.jpg"),
						name: " 谢先生",
						company: "通**通信股份有限公司",
						desc: "办理人员专业，耐心，有不懂的地方给我说的明明白白，找对地方了",
						date: "2020-08-21",
					},
					{
						icon: require("@/assets/image/contract/12.jpg"),
						name: "李生",
						company: "茂名***建设工程有限公司",
						desc: "可以办理资质也可以找人才，不用自己到处找，大力推荐",
						date: "2020-09-06",
					},
					{
						icon: require("@/assets/image/contract/13.jpg"),
						name: "陈生",
						company: "广州**绿化工程有限公司",
						desc: "缺少的人才也是这补全的，完全没有延误办理周期",
						date: "2020-07-02",
					},
					{
						icon: require("@/assets/image/contract/14.jpg"),
						name: "简女士",
						company: "珠海**机械设施工程管理有限公司",
						desc: "办事效率高，办理的人员非常专业，申报前反复核对资料，确保我一次过，提前拿证",
						date: "2020-05-20",
					},
					{
						icon: require("@/assets/image/contract/15.jpg"),
						name: "吴生",
						company: "深圳**建设工程有限公司",
						desc: "办理顺利，一次通过，需要增项资质再合作！",
						date: "2020-01-21",
					},
					{
						icon: require("@/assets/image/contract/16.jpg"),
						name: "张生",
						company: "深圳**建筑设计工程有限公司",
						desc: "给优普道点个赞，办事效率高，员工专业服务好！",
						date: "2019-04-20",
					},
					{
						icon: require("@/assets/image/contract/17.jpg"),
						name: "王工",
						company: "广州**工程有限公司",
						desc: "服务到位，跟进及时，取证很顺利",
						date: "2019-05-18",
					},
					{
						icon: require("@/assets/image/contract/18.jpg"),
						name: "邓生",
						company: "佛山**星建设工程公司",
						desc: "报价合理透明，一对一服务跟进及时，拿证很顺利，很不错的一次合作",
						date: "2019-10-20",
					},
					{
						icon: require("@/assets/image/contract/19.jpg"),
						name: " 郑女士",
						company: "珠海**工程管理有限公司 ",
						desc: "5个资质一起办理，不到4个月便拿证了。 ",
						date: "2019-06-29",
					},
				],
				videoList: 0,
				video_url: "",
				dataList: []
			};
		},
		methods: {
			// 触摸切换视频
			pickerVideo(index) {
				this.videoList = index;
				this.video_url = this.dataList[index].video_url;
			},
			getQuestions(data) {
				this.$api.getQuestions(data).then((res) => {
					this.dataList = res.data.list;
					this.video_url = res.data.list[0].video_url;
				})
			}
		},
		created() {

		},
		mounted() {

		},
		watch: {
			'fromData': {
				handler(newVal, oldVal) {
					this.getQuestions(newVal);
				},
				immediate: true,
			},
		}
	};
</script>

<style lang="scss" scoped>
	.video-main {
		padding: 70px 0;
	}

	.main-title {
		font-size: 36px;
		font-family: Source Han Sans CN;
		font-weight: bold;
		color: #383D41;
		padding-top: 0;
	}

	.video-box {
		height: 405px;

		.video-l {
			height: 100%;
			width: 100%;

			// background: #A00000;
			video {
				width: 100%;
				height: 100%;
			}
		}

		.video-r {
			background: rgba(255, 255, 255, 0.2);

			.video-r-list {
				padding: 18px 38px;
				border: 1px solid #D9DCE8;
				border-top: none;
				width: 394px;
				cursor: pointer;
				border-left: none;

				h4 {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #383D41;
					line-height: 22px;
				}

				span {
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #7C7E86;
					line-height: 16px;
				}
			}

			.active {
				h4 {
					color: #2070FF;
				}

				span {
					color: #2070FF;
				}
			}

			.video-r-list:nth-child(1) {
				border-top: 1px solid #D9DCE8;
			}

			.video-r-list:hover h4 {
				color: #2070FF;
			}

			.video-r-list:hover span {
				color: #2070FF;
			}
		}
	}

	@media only screen and (max-width: 1024px) {

		.connect {
			background: transparent;
		}

		.video-box {
			height: auto;
			margin-bottom: 40px;
			flex-wrap: wrap;
		}

		.main-title {
			font-size: 25px;
		}

		.video-box {

			.video-l {
				height: auto;
				width: 100%;
				flex: auto;
			}

			.video-r {
				width: 100%;
				.video-r-list {
					width: 100%;
					padding: 20px 10px;
				}
			}
		}
	}

	@media only screen and (max-width: 750px) {
		.choose-main {
			display: none;
		}

		.m-choose-main {
			display: block;
		}
	}

	@media only screen and (max-width: 481px) {
		.video-main {
			padding: 30px 0 1px 0;
		}

		.connect {
			background: transparent;
		}

		.video-box {
			height: auto;
			margin-bottom: 40px;
		}

		.main-title {
			font-size: 25px;
		}

		.video-box {

			.video-l {
				height: auto;
			}

			.video-r .video-r-list {
				width: 100%;
				padding: 20px 10px;
			}
		}
	}


	.video-mainnew {
		background-image: url(../../../assets/image/serve/bg-video.png);

		.main-title {
			color: #ffffff;
		}

		.video-box {
			.video-r {
				.video-r-list {
					h4 {
						color: #FFFFFF;
					}

					span {
						color: #B6B6EE;
					}

					&:hover h4 {
						color: #FFB820;
					}

					&:hover span {
						color: #FFB820;
					}
				}

				.active {
					h4 {
						color: #FFB820;
					}

					span {
						color: #FFB820;
					}
				}
			}
		}
	}
</style>
