import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "首页",
      bgColor: "#ffffff",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("../views/policy/policy.vue"),
    meta: {
      title: "政策资讯",
    },
  },
  {
    path: "/contract",
    name: "contract",
    component: () => import("../views/contract/contract.vue"),
    meta: {
      title: "总承包",
    },
  },
  {
    path: "/qualification",
    name: "qualification",
    component: () => import("../views/qualification/qualification.vue"),
    meta: {
      title: "专业承包",
    },
  },
  {
    path: "/decoration",
    name: "decoration",
    component: () => import("../views/decoration/decoration.vue"),
    meta: {
      title: "其他资质",
    },
  },
  {
    path: "/design",
    name: "design",
    component: () => import("../views/design/design.vue"),
    meta: {
      title: "设计类资质",
    },
  },
  {
    path: "/case",
    name: "case",
    component: () => import("../views/case/case.vue"),
    meta: {
      title: "成功案例",
      bgColor: "#F5F8FA",
    },
  },
  {
    path: "/article",
    name: "article",
    component: () => import("../views/article/article.vue"),
    meta: {
      title: "文章详情",
      bgColor: "#F5F8FA",
      breadList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "二级页面",
          path: "/default",
        },
        {
          name: "当前页面",
          path: "/article",
        },
      ],
    },
  },
  {
    path: "/study",
    name: "study",
    component: () => import("../views/study/study.vue"),
    meta: {
      title: "人才服务",
    },
  },
  {
    path: "/standard",
    name: "standard",
    component: () => import("../views/standard/standard.vue"),
    meta: {
      title: "资质标准",
      bgColor: "#F5F8FA",
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  if (to.name == "article") {
    let arr = to.meta.breadList;
    if (from.name == "Home") {
      arr.splice(1, 1);
    } else if (from.name == "article") {
      arr = arr;
    } else {
      arr.splice(1, 1, {
        name: from.meta.title,
        path: from.fullPath,
      });
    }
    to.meta.breadList = arr;
  }
  // 每次切换页面时，调用进度条
  document.title = "建筑资质代办-建筑资质办理-优普道资质代理平台"; //to.meta.title ? to.meta.title : '深圳优普道'
  document
    .querySelector('meta[name="keywords"]')
    .setAttribute(
      "content",
      "建筑资质代办,施工资质代办,资质代办,资质办理,资质代办公司,施工总包资质,市政总包资质,承装修试,钢结构专包资质,电子与智能化专包资质,机电安装专包资质,公路桥梁专包资质,消防设施专业承包资质,防水防腐保温工程专业承包资质,装修装饰专业承包资质,建筑幕墙工程专业承包资质,施工劳务资质,特种工程专业承包资质,建筑幕墙工程设计专项资质,房地产开发资质,安防资质,测绘资质,施工类安全生产许可证"
    );
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "优普道建筑资质代理平台多年来专注施工资质办理,资质分离,资质升级,资质转让,资质收购,资质合并等服务,专业服务团队全流程化办理，优秀人才顾问保证人才供应,项目经理带队监控流程节点,找专业的人办专业的事!资质代办就找优普道！"
    );
  document.body.style.background = to.meta.bgColor ? to.meta.bgColor : "#ffffff";
  NProgress.start();
  next();
});
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});

export default router;
