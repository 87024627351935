<template>
	<div class=" home news-main bg-cent" :style="{backgroundImage: ifcolor ? 'url(' + require('@/assets/image/home/news-bg.png') + ')' : '' }">

		<div class="home-policy container pb-6">
			<div class="home-policy-main" :class=" ifcolor ? 'home-policy-main-new' : '' ">
				<!-- menu -->
				<div class="scenes-menu">
					<div class="lists">
						<template v-for="(item, index) in categoryList">
							<div class="item" :class="{ active: sencesActive == index }" @mouseenter="slectScenes(index, item.id)" :key="index">
								{{ item.name }}
							</div>
						</template>
					</div>
					<div class="more pr-3 position-relative" @click="navLink">more <span class="position-absolute">+</span></div>
				</div>

				<div class="list">
					<template v-for="(item, index) in policyList">
						<div class="item" :key="index" @click="toArticle(item)" @mouseenter="enter(index, 'policy')" @mouseleave="leave()">
							<span class="time" :class="[mouseenterIndex == index ? 'spanbg' : '',ifcolor ? 'timenew' : '' ]">{{ item.create_time | getTime }}</span>
							<span class="title" :class="[mouseenterIndex == index ? 'spanColor' : '' , ifcolor ? 'titlenew' : '']">{{ item.title }}</span>
						</div>
					</template>
				</div>

			</div>
			<div class="home-policy-bg">
				<img :src="cover_url" alt="" />
			</div>
		</div>

	</div>
</template>

<script>
	import {
		formatDate
	} from "../../../assets/js/formDate.js";
	export default {
		props: {
			ifcolor: {
				type: String,
				default: ''
			}
		},
		filters: {
			getTime(val) {
				let c = new Date(val.replace(/-/g, "/"));
				return formatDate(c, "MM-dd");
			},
		},
		data() {
			return {
				categoryList: [{
						name: '国家政策',
						id: 0
					},
					{
						name: '资质标准',
						id: 1
					},
					{
						name: '行业动态',
						id: 2
					},
				],
				policyList: [],
				cover_url: '',
				industryList: [],
				mouseenterIndex: null,
				sencesActive: 0,
				industry: 0
			}
		},
		created() {
			this.getPolicy("policy");
			// this.getPolicy("industry"); 
			// this.getPolicy("icb");
		},
		methods: {
			// 点击更多
			navLink() {
				let url = ['/policy', '/standard', '/policy'];
				this.$router.push(url[this.sencesActive]);
			},
			// 触摸切换
			slectScenes(index, id) {
				this.sencesActive = index;
				this.industry = id;
				let type = ['policy', 'criterion', 'industry'];
				this.getPolicy(type[index]);
				// this.getIntelligentList();
				// // 接分类接口
			},
			// 国家政策 行业动态
			getPolicy(flag) {
				this.$api
					.getArticleList({
						flag: flag,
						limit: 6,
					})
					.then((res) => {
						this.policyList = res.data.list;
						this.cover_url = res.data.list.length != 0 ? this.policyList[0].cover_url : '';
					});
			},

			// 鼠标移入政策 行业
			enter(index, name) {
				this.mouseenterIndex = index;
				this.cover_url = this.policyList[index].cover_url;
			},
			leave() {
				this.mouseenterIndex = null;
			},
			toArticle(item) {
				this.$router.push({
					name: "article",
					query: {
						id: item.id,
						flag: item.flag || 0,
						except: item.except || 0,
						categoryId: item.category_id || 0,
						professionId: item.profession || 0,
					},
				});
			},

		}
	}
</script>

<style lang="scss" scoped>
	.scenes-menu {
		@include flex-between();

		h4 {
			font-size: 20px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #FFFFFF;
			margin-right: 25px;
		}

		.lists {
			padding: 0 20px;
			@include flex-start();
			border-bottom: 1px solid #E3E5EF;

			.item {
				padding: 0 20px 20px;
				font-size: 16px;
				color: #999999;
				line-height: 22px;
				cursor: pointer;
			}

			.active {
				color: $primary-color;
				position: relative;

				&::after {
					content: '';
					display: block;
					width: 50px;
					height: 3px;
					background: $primary-color;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}

		}

		.more {
			font-size: 18px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #999999;
			line-height: 22px;
			cursor: pointer;

			&:hover {
				color: #ffb820;
			}

			span {
				right: 15px;
				top: -5px;
			}
		}
	}

	.home-policy-main-new {}

	.news-main {
		min-height: 315px;
		// background-image: url(../../../assets/image/home/news-bg.png);
	}

	.home{
		
	.home-policy {
		// margin-top: 60px;
		padding: 60px 20px !important;
		display: flex;
		justify-content: space-between;

		&-main {
			width: 70%;

			.ant-tabs-bar {
				margin: 0;
				border: none;
				position: relative;

				&::after {
					content: "";
					width: 50%;
					height: 1px;
					background: #e3e5ef;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}

			.ant-tabs-nav .ant-tabs-tab {
				padding: 0px 26px 20px;
			}

			.list {
				@include flex-start();
				flex-wrap: wrap;
				min-height: 135px;

				.item {
					width: 50%;
					margin-top: 24px;
					@include flex-start();
					align-items: flex-start;
					cursor: pointer;
					padding-right: 3%;

					.time {
						text-align: center;
						width: 18%;
						padding: 0px 10px;
						background: #f9faff;
						color: #9CA0B7;
					}

					.timenew {
						color: #2070FF;
					}

					.spanbg {
						// background: #FFB820;
					}

					.title {
						margin-left: 18px;
						display: inline-block;
						width: 78%;
						color: #727585;
						@include ellipsis();

					}

					.titlenew {
						color: #FFFFFF;
					}

					.spanColor {
						// color: #2070FF;
					}
				}
			}
		}

		&-bg {
			width: 30%;

			img {
				width: 100%;
				// height: 100%;
				height: 195px;
			}
		}
	}
	}
	.home-policy-main {
		/deep/ .ant-tabs-nav .ant-tabs-tab-active {
			color: #335ED9;
		}

		/deep/ .ant-tabs-ink-bar {
			background-color: #335ED9;
		}

		/deep/ .ant-tabs-nav .ant-tabs-tab:hover {
			color: #335ED9;
		}

		.list .item .spanbg {
			background-color: #2070FF;
		}

		.list .item .spanColor {
			color: #2070FF;
		}
	}

	.home-policy-main-new {
		/deep/ .ant-tabs-nav .ant-tabs-tab-active {
			color: #FFB820;
		}

		/deep/ .ant-tabs-ink-bar {
			background-color: #FFB820;
		}

		/deep/ .ant-tabs-nav .ant-tabs-tab:hover {
			color: #FFB820;
		}

		.list .item .spanbg {
			background-color: #FFB820;
		}

		.list .item .spanColor {
			color: #FFB820;
		}

		.scenes-menu {
			@include flex-between();

			h4 {
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #FFFFFF;
				margin-right: 25px;
			}

			.lists {
				.item {
					color: #FFFFFF;
				}

				.active {
					color: #ffb820;

					&::after {
						background: #ffb820;
					}
				}

			}

			.more {
				color: #FFFFFF;

				&:hover {
					color: #ffb820;
				}
			}
		}

	}


	@media only screen and (max-width: 481px) {
		.scenes-menu {
			.more {
				display: none;
			}

			.lists .item {
				padding: 0 13px 20px;
			}
		}
	}


	@media only screen and (max-width: 1024px) {
		.home {
			.home-policy {
				// margin-top: 60px;
				display: flex;
				justify-content: space-between;

				&-main {
					width: 70%;

					.list {
						.item {
							.time {
								width: 25%;
							}

							.title {
								width: 75%;
							}
						}
					}


				}


			}
		}

	}

	@media only screen and (max-width: 750px) {
		.home {

			.home-policy {
				&-main {
					width: 100%;

					.list {
						.item {
							width: 100%;
							margin-top: 24px;
							@include flex-start();
							align-items: flex-start;
							cursor: pointer;
							font-size: 28px;

							.time {
								width: 24%;
								font-size: 24px;
								padding: 10px 10px;
								background: #f9faff;
								color: #9ca0b7;
								text-align: center;
							}

							.title {
								margin-left: 18px;
								display: inline-block;
								width: 76%;
								@include ellipsis();
							}
						}
					}

					.spanColor {
						color: #2070FF;
					}
				}

				&-bg {
					display: none;
				}
			}


		}

	}

	@media only screen and (max-width: 481px) {
		.home {

			.home-policy {
				&-main {
					width: 100%;

					.list {
						.item {
							font-size: 16px;

							.time {
								width: 20%;
								font-size: 16px;
								padding: 0;
							}

							.title {
								margin-left: 10px;
								display: inline-block;
								width: 80%;
								@include ellipsis();
							}
						}
					}
				}

				&-bg {
					display: none;
				}
			}


		}

	}
</style>
