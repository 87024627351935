<template>
  <div class="nav">
    <div
      class="nav-list"
      v-for="(item, index) in navList"
      :key="index"
      @click="navGo(index)"
      @mouseenter="showToast(index)"
      @mouseleave="hideToast(index)"
    >
      <img :src="item.test" alt="" />
      <span class="test-b">{{ item.name }}</span>
    </div>
    <div class="toast phone" v-show="showPhone">
      <a :href="'tel:' + $store.state.companyInfo.business_line">{{$store.state.companyInfo.business_line}}</a>
    </div>
    <div class="toast guwen" v-show="showGuwen">
      <img :src="$store.state.companyInfo.adviser_temp" alt="" />
    </div>
    <div class="toast connectKefu" v-show="showConnect">在线沟通</div>
    <div class="toast message" v-show="showMessage" @mouseleave="leaveMessage">
      <div class="item">
        <img src="@/assets/image/name.png" alt="" />
        <a-input
          class="m-input"
          ref="userNameInput"
          v-model="username"
          placeholder="请输入您的名字"
        >
        </a-input>
      </div>
      <div class="item">
        <img src="@/assets/image/phone1.png" alt="" />
        <a-input
          class="m-input"
          ref="userPhoneInput"
          onkeyup="value=value.replace(/[^\d]/g,'')"
          v-model="phone"
          placeholder="请输入联系电话"
        >
        </a-input>
      </div>
      <a-textarea
        class="m-textarea"
        v-model="question"
        placeholder="| 请输入留言内容"
        :rows="4"
      />
      <a-button class="submit" type="primary" @click="setMessage">
        提交留言
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      phone: "",
      question: "",
      timeMessage: null,
      showPhone: false,
      showGuwen: false,
      showConnect: false,
      showMessage: false,
      navList: [
        {
          test: require("@/assets/image/28.png"),
          name: "电话咨询",
        },
        {
          test: require("@/assets/image/29.png"),
          name: "在线咨询",
        },
        {
          test: require("@/assets/image/30.png"),
          name: "顾问咨询",
        },
        {
          test: require("@/assets/image/31.png"),
          name: "意见反馈",
        },
        {
          test: require("@/assets/image/32.png"),
          name: "TOP",
        },
      ],
    };
  },
  methods: {
    setMessage() {
      let source_url = window.location.href;
      if (this.username == "") {
        this.$message.error("请输入您的姓名");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$message.error("请输入正确手机号");
        return;
      }
      if (this.question == "") {
        this.$message.error("请输入您的留言");
        return;
      }
      let data = {
        channel: 10,
        username: this.username,
        phone: this.phone,
        requirements: this.question,
        source_url: source_url,
      };
      if (this.$mobile) {
        data.channel = 20;
      }
      this.$api.applyOffer(data).then((res) => {
        this.$message.success("留言提交成功，稍后专业顾问将为你答疑解惑");
        this.question = "";
        this.username = "";
        this.phone = "";
      });
    },
    enterMessage() {
      clearTimeout(this.timeMessage);
      this.showMessage = true;
    },
    leaveMessage() {
      this.timeMessage = setTimeout(() => {
        this.showMessage = false;
      }, 1000);
    },
    toTop(index) {
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
      let step = distance / 30; //每步的距离
      (function jump() {
        if (distance > 0) {
          distance -= step;
          window.scrollTo(0, distance);
          setTimeout(jump, 10);
        }
      })();
    },
    navGo(index) {
      if (index == 1) {
        this.$toConnectLink()
        return;
      }
      if (index == 4) {
        this.toTop();
        return;
      }
      if (index == 3) {
        this.showMessage = !this.showMessage;
        return;
      }
    },
    showToast(index) {
      if (index == 0) {
        this.showPhone = true;
        return;
      }
      if (index == 1) {
        this.showConnect = true;
        return;
      }
      if (index == 2) {
        this.showGuwen = true;
        return;
      }
    },
    hideToast(index) {
      if (index == 0) {
        this.showPhone = false;
        return;
      }
      if (index == 1) {
        this.showConnect = false;
        return;
      }
      if (index == 2) {
        this.showGuwen = false;
        return;
      }
      // if (index == 3) {
      // 	this.timeMessage = setTimeout(() => {
      // 		this.showMessage = false;
      // 	}, 2000)
      // }
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.nav {
  width: 88px;
  background: #ffffff;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  z-index: 999;
  text-align: center;
  border: 1px solid #f5f8fa;

  .nav-list {
    border-bottom: 1px solid #f5f8fa;
    box-sizing: border-box;
    cursor: pointer;
    padding: 14px 0;

    .test-t {
      font-size: 30px;
      color: #2070ff;
      display: block;
      line-height: normal;
    }

    .test-b {
      margin-top: 7px;
      font-size: 13px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #9ca0b7;
      display: block;
    }
  }

  .toast {
    position: absolute;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    // border: 1px solid #F5F8FA;
    min-width: 140px;
  }

  .guwen {
    top: 150px;
    left: -160px;

    img {
      width: 100px;
    }
  }

  .phone {
    top: 10px;
    left: -160px;

    a {
      font-size: 16px;
      font-weight: bold;
      color: $primary-color;
    }
  }

  .connectKefu {
    padding: 10px;
    top: 110px;
    left: -160px;
    font-size: 16px;
    font-weight: bold;
    color: $primary-color;
  }

  .message {
    padding: 20px;
    top: 0;
    left: -250px;
    width: 230px;
    background: #ffffff;

    .item {
      @include flex-around();
      align-items: center;
      margin-bottom: 24px;

      img {
        // width: 12px;
        // height: auto;
      }

      .m-input {
        height: 45px;
        margin-left: 10px;
        outline: none;
        border: none;
        border-bottom: 1px solid #f5f8fa;
      }

      .m-input:focus {
        box-shadow: none;
      }
    }

    .m-textarea {
      padding: 16px 0;
      height: 179px;
      text-indent: 10px;
      background: #f5f8fa;
      border: 1px solid #e4e8eb;
      resize: none;
    }

    .m-textarea:focus {
      box-shadow: none;
    }

    .submit {
      margin-top: 24px;
      width: 100%;
      height: 36px;
      background: #345dd9;
    }
  }
}
@media only screen and (max-width: 750px) {
  .nav {
    display: none;
  }
}
</style>
