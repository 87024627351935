import Vue from 'vue'
import Vuex from 'vuex'
import api from "../api/api";
import {
	setw
} from '../api/config.js'

Vue.use(Vuex)

const store = new Vuex.Store({
	//状态
	state: {
		loadding: false,
		companyInfo: {
			address: '',
			admin_id: '',
			area_name: '',
			baidu_code: '',
			business_line: '',
			city_id: '',
			contact: '',
			create_time: '',
			delete_time: '',
			district_id: '',
			domain: '',
			email: '',
			external_code: '',
			external_service: '',
			flag: '',
			id: '',
			intro: '',
			legal_person: '',
			lng_lat: '',
			mark: '',
			name: '',
			operator_id: '',
			province_id: '',
			show_site: '',
			site_chinese: '',
			status: '',
			subdoadmin: '',
			telphone: '',
			update_time: '',
			zipcode: '',
			copyright: '',
			adviser: '',
			adviser_temp: ''
		}
	},
	//状态修改方法
	// 使用store.commit()来调用
	mutations: {
		// loadding
		showloadding(state, load) {
			state.loadding = load
		},
		setCompanyInfo(state, object) {
			console.log(object, 'mutation')
			state.companyInfo = object;
		},
	},
	//调用mutations，可以包含异步操作
	// 可以用store.dispatch来调用
	actions: {
		setloadding(context, load) {
			context.commit("showloadding", load);
		},
		getFilialeInfo(context, load) {
			return new Promise((resolve, reject) => {
				//获取站点基本数据
				api.getFilialeInfo().then(res => {
					if (!res.data.adviser_temp) res.data.adviser_temp = require('@/assets/image/zygw.png');

					if (setw) {
						api.getInfo().then(info => {
							if (!info.data.adviser_temp) info.data.adviser_temp = require('@/assets/image/zygw.png');
							let data = info.data;
							res.data.baidu_code = data.baidu_code;
							res.data.business_line = data.business_line;
							res.data.adviser_temp = data.adviser_temp;
							res.data.external_code = data.external_code;
							res.data.external_service = data.external_service;
							context.commit('setCompanyInfo', res.data);
							resolve();
						})
						// console.log('aaaaasdd',res.data.baidu_code)
					}else{
						context.commit('setCompanyInfo', res.data);
						resolve();
					}
				})
			})
		}

	},
	getters: {
		isloading: (state) => {
			return state.loadding
		}
	}
})
// 导出!!!!
export default store
