<template>
  <div class="blessingContainer" v-if="showBlessing">
    <img
      class="close"
      @click="closeBlessing"
      src="@/assets/image/icon-close.png"
      alt=""
    />
    <img class="blessing" src="@/assets/image/newYear.png" alt="" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      showBlessing: true,
      timer: null,
    };
  },
  methods: {
    closeBlessing() {
      clearTimeout(this.timer);
      this.showBlessing = false;
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.showBlessing = false;
    }, 10000);
  },
};
</script>
<style lang='scss'>
.blessingContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: url("~@/assets/image/newYear.png") no-repeat;
  background-size: cover;
  background-position: center 0;
  .close {
    position: absolute;
    top: 60%;
    right: 22%;
    z-index: 99;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .blessing {
    width: 100%;
    opacity: 0;
  }
}
@media only screen and (max-width: 750px) {
    .close {
        display: none;
    }
}
</style>