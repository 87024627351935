<template>
  <div class="loading">
    <a-spin
      size="large"
      :spinning="this.$store.state.loadding"
      tip="拼命加载数据中..."
    >
    </a-spin>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.loading {
  position: fixed;
  right: 20px;
  bottom: 20px;
  transform: translate(-50%, -50%);
}
</style>