let company = [
  {
    // 深圳
    name: "sz.youpudao.com",
    subdoadmin: "sz",
  },
  {
    // 珠海
    name: "zh.youpudao.com",
    subdoadmin: "zh",
  },
  {
    // 天津
    name: "tj.youpudao.com",
    subdoadmin: "tj",
  },
  {
    // 江西
    name: "jx.youpudao.com",
    subdoadmin: "jx",
  },
  {
    // 重庆
    name: "cq.youpudao.com",
    subdoadmin: "cq",
  },
  {
    // 广西
    name: "gx.youpudao.com",
    subdoadmin: "gx",
  },
  {
    // 海南
    name: "hn.youpudao.com",
    subdoadmin: "hn",
  },
  {
    // 四川
    name: "sc.youpudao.com",
    subdoadmin: "sc",
  },
  {
    // 浙江
    name: "zj.youpudao.com",
    subdoadmin: "zj",
  },
  {
    // 北京
    name: "bj.youpudao.com",
    subdoadmin: "bj",
  },
  {
    // 内蒙古
    name: "nmg.youpudao.com",
    subdoadmin: "nmg",
  },
  {
    // 吉林
    name: "jlypd.com",
    subdoadmin: "jl",
  },
  {
    // 青海
    name: "qh.youpudao.com",
    subdoadmin: "qh",
  },
  {
    // 上海
    name: "sh.youpudao.com",
    subdoadmin: "sh",
  },
  {
    // 河南
    name: "hn1.youpudao.com",
    subdoadmin: "hn1",
  },
  {
    // 江苏
    name: "js.youpudao.com",
    subdoadmin: "js",
  },
  {
    // 贵州
    name: "gz.youpudao.com",
    subdoadmin: "gz",
  },
  {
    // 陕西
    name: "sx1.youpudao.com",
    subdoadmin: "sx1",
  },
  {
    // 黑龙江
    name: "hlj.youpudao.com",
    subdoadmin: "hlj",
  },
  {
    // 山西
    name: "sx.youpudao.com",
    subdoadmin: "sx",
  },
  {
    // 甘肃
    name: "gs.youpudao.com",
    subdoadmin: "gs",
  },
  {
    //辽宁
    name: "ln.youpudao.com",
    subdoadmin: "ln",
  },
  {
    //福建
    name: "fj.youpudao.com",
    subdoadmin: "fj",
  },
];

export const host = "http://sadmin.youpudao.com/api"; //正式线
// const host = 'http://admin.youpudao.cn'; //正式线
export const devHost = "http://company.maolvxiansheng.top"; // 测试线
let url = window.location.hostname;

if (url.indexOf("www.") != -1) {
  var reg = new RegExp("www.");
  url = url.replace(reg, "");
}

let c = "www";
let setwww = "";
let development = process.env.NODE_ENV == "development" ? true : false;
if (!development) {
  let hadCompany = company.filter((item) => {
    return url.indexOf(item.name) !== -1;
  });
  if (hadCompany.length == 0) {
    let str = url.split(".")[0];
    c = str == "youpudao" ? "www" : str;
    setwww = "www";
  } else {
    c = hadCompany[0].subdoadmin;
  }
}
export const setw = setwww;
export const subdoadmin = c;
