// 公用组件
import Vue from 'vue'

// import ViewUI from 'view-design';
// import 'view-design/dist/styles/iview.css';
import videolist from "./service/video-list.vue";
import menus from "./service/menu.vue";
import strengthnew from './service/strength.vue'
import news from './home/news.vue'
import certificate from './home/certificate.vue'
import strength from './strength.vue'

const Components = {
  install: function (Vue) {
    Vue.component('videolist', videolist);
	Vue.component('menus', menus);
	Vue.component('news', news);
	Vue.component('strengthnew', strengthnew);
	Vue.component('certificate', certificate);
	Vue.component('strength', strength);
  }
}

Vue.use(Components);
// Vue.use(ViewUI);
