<template>
  <div id="app">
    <loading></loading>
    <Header></Header>
    <router-view />
    <Footer></Footer>
    <!-- <a-back-top /> -->
    <!-- 右侧导航 -->
    <navcent></navcent>
	
    <pop></pop>
	<!-- <blessing></blessing> -->
  </div>
</template>
<script>
import Header from "@/components/common/header";
import Footer from "@/components/common/footer";
import loading from "@/components/common/loading";
import navcent from "@/components/common/nav.vue";
import pop from "@/components/common/pop-ups.vue";
import blessing from '@/components/common/blessing.vue'
var options = {
  animateThreshold: 100,
  scrollPollInterval: 20,
};
export default {
  components: {
    Header,
    Footer,
    loading,
    navcent,
    pop,
	blessing
  },
  methods: {},
  created() {
    this.$store.dispatch("getFilialeInfo").then(() => {
      var baidu_code = this.$store.state.companyInfo.baidu_code;
      if (baidu_code !== "") {
        baidu_code = baidu_code.replace(/<[^>]+>/g, "");
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.text = baidu_code;
        document.getElementsByTagName("head")[0].appendChild(script);
      } else {
        var _hmt = [];
      }
	  
	  var external_code = this.$store.state.companyInfo.external_code;
	  if (external_code !== "") {
	    external_code = external_code.replace(/<[^>]+>/g, "");
	    let script = document.createElement("script");
	    script.type = "text/javascript";
	    script.text = external_code;
	    document.getElementsByTagName("head")[0].appendChild(script);
	  } else {
	    // var _hmt = [];
	  }
	  
    });
  },
};
</script>

<style scoped="scoped">
@import "./assets/css/icon.css";
@import "./assets/css/animate.min.css";
@import "./assets/css/public.scss";
@import "./assets/css/free.css";
#app {
  padding-top: 70px;
}
@media only screen and (max-width: 481px) {
  #app {
    padding-top: 45px;
  }
}
</style>
