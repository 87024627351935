import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import api from './api/api'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
import "babel-polyfill";
import "@/components/common/common.js"

import axios from 'axios';
Vue.prototype.$axios = axios;

var u = navigator.userAgent;
let ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
let mobile = !!u.match(/AppleWebKit.*Mobile.*/)
NProgress.configure({
	easing: 'ease', // 动画方式    
	speed: 500, // 递增进度条的速度    
	showSpinner: false, // 是否显示加载ico    
	trickleSpeed: 200, // 自动递增间隔    
	minimum: 0.3 // 初始化时的最小百分比
})

Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.prototype.$ios = ios;
Vue.prototype.$mobile = mobile;
Vue.prototype.$toConnectLink = () => {
	if (ios) {
		window.location.href = store.state.companyInfo.external_service;
	} else {
		window.open(store.state.companyInfo.external_service, '_blank', 'height=650, width=850, top=200, left=200, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no') 
		// var tempwindow = window.open('_blank');
		// tempwindow.location = store.state.companyInfo.external_service;
	}
};

Vue.use(antd)
Vue.use(VueAwesomeSwiper)


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')