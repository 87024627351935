<template>
  <div class="header-container">
    <div class="header">
      <div class="logo" @click="toHome">
        <img src="@/assets/image/logo.png" alt="优普道" />
      </div>
      <a-menu
        class="header-menu"
        :selectedKeys="[$route.path]"
        mode="horizontal"
      >
        <template v-for="item in menu">
          <a-menu-item
            :key="item.route"
            @mouseover="showSubMenu(item)"
            @mouseleave="hideSubMenu(item)"
          >
            <template v-if="!item.child">
              <router-link :to="item.route">{{ item.name }}</router-link>
            </template>
            <template v-else>
              <!-- {{item.name}} -->
              <router-link :to="item.route"
                ><img src="@/assets/image/server.png" alt=""
              /></router-link>
            </template>
          </a-menu-item>
        </template>
      </a-menu>
      <div class="serach">
        <a-input-search
          placeholder="请输入您咨询的服务"
          v-model="value"
          @search="onSearch"
        />
      </div>

      <a class="phone" :href="'tel:' + $store.state.companyInfo.business_line"
        ><img class="mr-1" src="@/assets/image/phone.png" />
        {{ $store.state.companyInfo.business_line }}</a
      >
      <div class="m-open-menu" @click="openMenu">
        <img src="@/assets/image/menu.png" alt="" />
      </div>
      <div
        class="sub-menu"
        v-show="isShowSubMenu"
        @mouseenter="enterSubMenu"
        @mouseleave="leaveSubMenu"
      >
        <template v-for="(item, index) in childMenu">
          <div class="sub-list" :key="index">
            <div class="item">
              <div class="title">
                <img :src="item.icon" alt="" />
                <span class="first" @click="changeRoute(item.route)">
                  <router-link :to="item.route">{{ item.name }}</router-link>
                </span>
              </div>
              <div class="child">
                <template v-for="(child, idx) in item.list">
                  <span :key="idx" @click="changeRoute(child.route)">
                    <router-link :to="child.route">{{
                      child.name
                    }}</router-link>
                  </span>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="m-menu" v-if="showMenu" @click.stop="closeMenu">
        <div class="menu-main" @click.stop>
          <div class="close-menu">
            <div class="img-box" @click="closeMenu">
              <img src="@/assets/image/close.png" alt="" />
            </div>
          </div>
          <a-menu :selectedKeys="[$route.path]" mode="inline">
            <template v-for="(item, index) in menu">
              <template v-if="!item.child">
                <a-menu-item
                  :class="{ last: index == menu.length - 1 }"
                  :key="item.route"
                  @click="closeMenu"
                >
                  <router-link :to="item.route">{{ item.name }}</router-link>
                </a-menu-item>
              </template>
              <template v-else>
                <a-sub-menu class="m-sub-menu" :key="item.route">
                  <span slot="title">{{ item.name }}</span>
                  <template v-for="child in item.child">
                    <a-menu-item :key="child.route" @click="closeMenu">
                      <router-link :to="child.route">{{
                        child.name
                      }}</router-link>
                    </a-menu-item>
                  </template>
                </a-sub-menu>
              </template>
            </template>
          </a-menu>
          <div class="m-search">
            <a-input-search
              placeholder="请输入您咨询的服务"
              v-model="value"
              @search="onSearch"
            />
            <a-button @click="onSearch">搜索</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: "",
      value: this.$route.query.name || "",
      isShowSubMenu: false,
      childMenu: [],
      timer: "",
      menu: [
        {
          name: "首页",
          route: "/",
        },
        {
          name: "资质服务",
          route: "/qualification",
          child: [
            {
              name: "总承包资质",
              route: "/contract",
              icon: require("@/assets/image/41.png"),
              list: [
                {
                  route: "/contract",
                  name: "施工总承包资质",
                },

                {
                  route: "/contract",
                  name: "建筑工程施工总承包",
                },
                {
                  route: "/contract",
                  name: "公路工程施工总承包",
                },
                {
                  route: "/contract",
                  name: "水利水电工程施工总承包",
                },
                {
                  route: "/contract",
                  name: "电力工程施工总承包",
                },
                {
                  route: "/contract",
                  name: "市政公用工程施工总承包",
                },
                {
                  route: "/contract",
                  name: "通信工程施工总承包",
                },

                {
                  route: "/contract",
                  name: "机电工程施工总承包",
                },
              ],
            },
            {
              name: "专业承包资质",
              route: "/qualification",
              icon: require("@/assets/image/42.png"),
              list: [
                {
                  route: "/qualification",
                  name: "地基基础工程专业承包",
                },

                {
                  route: "/qualification",
                  name: "电子与智能化工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "消防设施工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "防水防腐保温工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "钢结构工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "模板脚手架专业承包",
                },
                {
                  route: "/qualification",
                  name: "建筑装修装饰工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "建筑机电安装工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "建筑幕墙工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "城市及道路照明工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "公路交通工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "水利水电机电安装工程专业承包",
                },
                {
                  route: "/qualification",
                  name: "输变电工程专业承包",
                },
              ],
            },
            {
              name: "设计类资质",
              route: "/design",
              icon: require("@/assets/image/43.png"),
              list: [
                {
                  route: "/design",
                  name: "电力行业工程设计",
                },
                {
                  route: "/design",
                  name: "建筑工程设计",
                },

                {
                  route: "/design",
                  name: "市政行业工程设计",
                },
                {
                  route: "/design",
                  name: "公路行业工程设计",
                },
                {
                  route: "/design",
                  name: "建筑智能化系统化设计专项",
                },
                {
                  route: "/design",
                  name: "建筑幕墙工程设计专项",
                },
                {
                  route: "/design",
                  name: "建筑装饰工程设计专项",
                },
                {
                  route: "/design",
                  name: "轻型钢结构工程设计专项",
                },
                {
                  route: "/design",
                  name: "照明工程设计专项",
                },
              ],
            },
            {
              name: "其他类资质",
              route: "/decoration",
              icon: require("@/assets/image/44.png"),
              list: [
                {
                  route: "/decoration",
                  name: "承装修试资质",
                },
                {
                  route: "/decoration",
                  name: "劳务资质",
                },
                {
                  route: "/decoration",
                  name: "电力设施许可",
                },
                {
                  route: "/decoration",
                  name: "安全生产许可证",
                },
                {
                  route: "/decoration",
                  name: "电力相关资质",
                },
                {
                  route: "/decoration",
                  name: "人才证书培训、延续",
                },
                {
                  route: "/decoration",
                  name: "电气施工设备",
                },
              ],
            },
          ],
        },
        {
          name: "成功案例",
          route: "/case",
        },
        {
          name: "政策资讯",
          route: "/policy",
        },
        {
          name: "人才服务",
          route: "/study",
        },
        {
          name: "关于我们",
          route: "/about",
        },
      ],
    };
  },
  methods: {
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    openMenu() {
      document.body.style.overflow = "hidden";
      this.showMenu = true;
    },
    closeMenu() {
      document.body.style.overflow = "auto";
      this.showMenu = false;
    },
    toHome() {
      this.$router.push("/");
    },
    changeRoute(val) {
      this.isShowSubMenu = false;
      this.menu[1].route = val;
    },
    onSearch(e) {
      document.body.style.overflow = "auto";
      this.showMenu = false;
      this.$router.push({
        name: "case",
        query: {
          name: e || this.value,
        },
      });
    },
    showSubMenu(val) {
      if (val.child) {
        this.childMenu = val.child;
        this.isShowSubMenu = true;
      }
    },
    hideSubMenu(val) {
      if (val.child) {
        this.timer = setTimeout(() => {
          this.childMenu = [];
          this.isShowSubMenu = false;
        }, 300);
      }
    },
    enterSubMenu() {
      this.isShowSubMenu = true;
      clearTimeout(this.timer);
    },
    leaveSubMenu() {
      this.timer = setTimeout(() => {
        this.isShowSubMenu = false;
      }, 300);
    },
  },
  mounted() {},
  watch: {
    $route(value) {
      this.value = value.query.name || "";
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/css/public.scss";
</style>
