<template>
  <div class="cstrength" :style="ifcolor ? 'background: #FAFAFB' : '' ">
	 <div>
    <div class="main-title">
      <h1>优普道实力见证</h1>
      <h3>优普道一站式企业服务，让建筑资质办理更便捷</h3>
    </div>
		<div class="cstrength-main">
      <div class="strength-menu">
        <template v-for="(item, index) in strengthMenu">
          <div
            class="item"
            :key="index"
            @mouseenter="changeStrengthMenu(index)"
            @mouseleave="strengthHover = false"
            :class="{ active: strengthActive == index }"
          >
            <div class="img-box">
              <img
                :src="strengthActive == index ? item.activeIcon : item.icon"
                alt=""
              />
            </div>
            <p class="name" v-if="item.name">{{ item.name }}</p>
          </div>
        </template>
      </div>
      <div class="m-strength-menu">
        <template v-for="(item, index) in strengthMenu">
          <div
            class="item"
            :key="index"
            @click="changeStrengthMenu(index)"
            :class="{ active: strengthActive == index }"
          >
            <p class="name" v-if="item.name">{{ item.name }}</p>
          </div>
        </template>
      </div>
      <div
        class="strength-content"
        :class="{
          noList: strengthContent.list.length == 0,
          fadeIn: strengthHover,
        }"
        :style="{
          backgroundImage: 'url(' + strengthContent.bgImg + ')',
          backgroundSize: 'contain',
        }"
      >
        <div class="title">
          {{ strengthContent.title }}
        </div>
        <div class="desc">
          {{ strengthContent.desc }}
        </div>
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in strengthContent.list"
            :key="index"
            :class="{ noName: !item.name }"
          >
            <div class="img-box animated" :class="{ zoomIn: strengthHover }">
              <img :src="item.img" alt="" />
            </div>
            <p class="name">{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
	</div>
  </div>
</template>

<script>
export default {
  props:{
	ifcolor:{
		type:String,
		default:''
	}  
  },
  data() {
    return {
      strengthHover: false,
      strengthContent: null,
      strengthActive: 0,
      strengthMenu: [
        {
          name: "经验沉淀",
          icon: require("@/assets/image/decoration/83.png"),
          activeIcon: require("@/assets/image/decoration/87.png"),
          content: {
            title: "经验沉淀",
            desc:
              "专注建工行业15年，为建企提供资质、教育、人才和财税等多维服务，为建企保驾护航， 一站式免费咨询，一次收费终身受用。",
            list: [
              {
                img: require("@/assets/image/080.png"),
                name: "多个项目版块启动",
              },
              {
                img: require("@/assets/image/081.png"),
                name: "实现全国战略部署",
              },
              {
                img: require("@/assets/image/082.png"),
                name: "汇聚精英 再创辉煌",
              },
            ],
            bgImg: "",
          },
        },
        {
          name: "业务范围",
          icon: require("@/assets/image/decoration/80.png"),
          activeIcon: require("@/assets/image/decoration/86.png"),
          content: {
            title: "业务范围",
            desc:
              "优普道目前开设了30余家省外分公司，旗下拥有五大板块，八大业态，4个子品牌，超过 200多种服务项目实现建企全生命周期服务闭环。",
            list: [
              {
                img: require("@/assets/image/083.png"),
              },
              {
                img: require("@/assets/image/084.png"),
              },
              {
                img: require("@/assets/image/085.png"),
              },
            ],
            bgImg: "",
          },
        },
        {
          name: "媒体报道",
          icon: require("@/assets/image/decoration/82.png"),
          activeIcon: require("@/assets/image/decoration/85.png"),
          content: {
            title: "媒体报道",
            desc:
              "优普道作为领跑行业的一站式建企咨询服务平台，有良好的兴业地位、社会责任感，有较多 的新闻报道，在社会各界的关注下，优普道将会越来越好。",
            list: [
              {
                img: require("@/assets/image/086.png"),
                name: "多个项目版块启动",
              },
              {
                img: require("@/assets/image/087.png"),
                name: "实现全国战略部署",
              },
              {
                img: require("@/assets/image/088.png"),
                name: "汇聚精英 再创辉煌",
              },
            ],
            bgImg: "",
          },
        },
        {
          name: "客户体量",
          icon: require("@/assets/image/decoration/81.png"),
          activeIcon: require("@/assets/image/decoration/84.png"),
          content: {
            title: "客户体量",
            desc:
              "累计至今，优普道已经服务超8万余家建筑企业，累积服务超15万余客户，并与10万余家建企 签署了长期合作协议。",
            list: [],
            bgImg: require("@/assets/image/089.png"),
          },
        },
      ],
    };
  },
  methods: {
    changeStrengthMenu(index) {
      if (this.strengthActive != index) {
        this.strengthHover = true;
      }
      this.strengthActive = index;
      this.strengthContent = this.strengthMenu[index].content;
    },
  },
  created() {
    this.strengthContent = this.strengthMenu[this.strengthActive].content;
  },
};
</script>

<style lang="scss" scoped>
.main-title{
		padding-top: 70px;
		h1{
			font-size: 36px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #383D41;
		}
		h3{
			font-size: 16px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #686A73;
			margin-top: 10px;
		}
	}
	.cstrength{
		padding-bottom: 70px;
	}
.cstrength-main {
  
  @include flex-start();
  background: #f9f9fb;

  .strength-menu {
    padding: 10px 10px 0;
    width: 30%;
    @include flex-between();
    flex-wrap: wrap;

    .item {
      width: 48.4%;
      min-height: 129px;
      background: #ffffff;
      border: 1px solid #f9faff;
      cursor: pointer;
      margin-bottom: 10px;
      @include flex-center(column);
      transition: all 0.5s;

      .name {
        font-size: 18px;
        color: #383d41;
      }
    }

    .active {
      background: $primary-color;

      .name {
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
  .m-strength-menu {
    display: none;
  }

  .strength-content {
    padding: 10px 10px 0 50px;
    width: 70%;

    .title {
      margin-top: 6px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #383d41;

      &::after {
        content: "";
        display: block;
        width: 45px;
        height: 2px;
        background: #2070ff;
        margin-top: 6px;
      }
    }

    .desc {
      padding-right: 20px;
      margin-top: 10px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #727585;
    }

    .list {
      margin-top: 10px;
      @include flex-between();

      .item {
        width: 32%;

        .img-box {
          width: 100%;
          min-height: 105px;

          img {
            width: 100%;
          }
        }

        .name {
          margin-top: 4px;
          font-size: 16px;
          font-weight: 400;
          color: #9ca0b7;
          text-align: center;
        }
      }

      .noName {
        .img-box {
          margin-top: 20px;
        }
      }
    }
  }

  .noList {
    .title {
      margin-top: 50px;
    }

    .desc {
      margin-top: 30px;
    }
  }
}
@media only screen and (max-width: 750px) {
  .cstrength-main {
    margin-bottom: 100px;
    @include flex-start();
    flex-wrap: wrap;
    background: #f9f9fb;

    .strength-menu {
      display: none;
    }
    .m-strength-menu {
      width: 100%;
      display: block;
      margin-left: 10px;
      @include flex-start();

      .item {
        width: 24.6%;
        background: #ffffff;
        border: 1px solid #eeeef4;
        transform: skewX(20deg);
        .name {
          padding: 12px 0;
          text-align: center;
          font-size: 20px;
          transform: skewX(-20deg);
        }
      }
      .active {
        background: #f9f9fb;
        border: none;
      }
    }

    .strength-content {
      width: 100%;
      padding: 20px 12px;

      .title {
        display: none;
      }

      .desc {
        font-size: 20px;
      }

      .list {
        margin-top: 10px;

        .item {
          width: 32%;

          .img-box {
            width: 100%;
            min-height: 105px;

            img {
              width: 100%;
            }
          }

          .name {
            margin-top: 8px;
            font-size: 18px;
          }
        }

        .noName {
          .img-box {
            margin-top: 20px;
          }
        }
      }
    }

    .noList {
      .title {
        margin-top: 50px;
      }

      .desc {
        margin-top: 30px;
      }
    }
  }
}
@media only screen and (max-width: 481px) {
	.main-title {
	    padding-top: 40px;
	}
	.cstrength{
		padding-bottom: 40px;
	}
  .cstrength-main {
    margin-bottom: 0px;
    @include flex-start();
    flex-wrap: wrap;
    background: #f9f9fb;

    .strength-menu {
      display: none;
    }
    .m-strength-menu {
      width: 95%;
      // // display: block;
      // margin-left: 8px;
      // @include flex-start();

      .item {
        width: 24.6%;
        background: #ffffff;
        border: 1px solid #eeeef4;
        transform: skewX(20deg);
        .name {
          padding: 6px 0;
          font-size: 16px;
        }
      }
      .active {
        background: #f9f9fb;
        border: none;
      }
    }

    .strength-content {
      width: 100%;
      padding: 20px 12px;

      .title {
        display: none;
      }

      .desc {
        font-size: 16px;
      }

      .list {
        margin-top: 10px;

        .item {
          width: 32%;

          .img-box {
            width: 100%;
            min-height: auto;

            img {
              width: 100%;
            }
          }

          .name {
            margin-top: 8px;
            font-size: 12px;
          }
        }

        .noName {
          .img-box {
            margin-top: 20px;
          }
        }
      }
    }

    .noList {
      .title {
        margin-top: 50px;
      }

      .desc {
        margin-top: 30px;
      }
    }
  }
}
</style>
